import { render, staticRenderFns } from "./BottomAssembly.vue?vue&type=template&id=09aa1eee&scoped=true&"
import script from "./BottomAssembly.vue?vue&type=script&lang=js&"
export * from "./BottomAssembly.vue?vue&type=script&lang=js&"
import style0 from "./BottomAssembly.vue?vue&type=style&index=0&id=09aa1eee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09aa1eee",
  null
  
)

export default component.exports