import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    //首页
    path: '/',
    name: 'Photo',
    component: () => import('../views/PhotoView.vue'),
    // meta: { isAuth: true }
  },
  // {
  //   //首页
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView,
  //   // meta: { isAuth: true }
  // },
  // {
  //   //登录
  //   path: '/',
  //   name: 'Sign',
  //   component: () => import('../views/SignView.vue')
  // },
  // {
  //   //个人中心
  //   path: '/Personal',
  //   name: 'Personal',
  //   component: () => import('../views/PersonalView.vue'),
  //   children: [
  //     {
  //       path: '/',
  //       component: () => import('../views/PersonalChildren/PersonalData.vue'),
  //       meta: { isAuth: true }
  //     },
  //     {
  //       path: 'MyCollection',
  //       component: () => import('../views/PersonalChildren/MyCollection.vue'),
  //       meta: { isAuth: true }
  //     },
  //     {
  //       path: 'FeedBack',
  //       component: () => import('../views/PersonalChildren/FeedBack.vue'),
  //       meta: { isAuth: true }
  //     },
  //     {
  //       path: 'Agreement',
  //       component: () => import('../views/PersonalChildren/Agreement.vue'),
  //       meta: { isAuth: true }
  //     }
  //   ],
  //   meta: { isAuth: true }
  // },
  // {
  //   //图片详情
  //   path: '/Photodetails',
  //   name: 'Photodetails',
  //   component: () => import('../views/PhotodetailsView.vue'),
  //   // meta: { isAuth: true }
  // },
  // {
  //   //视频详情
  //   path: '/Videodetails',
  //   name: 'Videodetails',
  //   component: () => import('../views/VideodetailsView.vue'),
  //   meta: { isAuth: true }
  // },
  // {
  //   //关于我们
  //   path: '/AboutMy',
  //   name: 'AboutMy',
  //   component: () => import('../views/AboutMyView.vue'),
  //   // meta: { isAuth: true }
  // },
  {
    //图片
    path: '/Photo',
    name: 'Photo',
    component: () => import('../views/PhotoView.vue'),
    // meta: { isAuth: true }
  },
  // {
  //   //价格表
  //   path: '/Price',
  //   name: 'Price',
  //   component: () => import('../views/PriceView.vue'),
  //   // meta: { isAuth: true }
  // },
  // {
  //   //帖子详情
  //   path: '/ShareDetails',
  //   name: 'ShareDetails',
  //   component: () => import('../views/ShareDetailsView.vue'),
  //   // meta: { isAuth: true }
  // },
  // {
  //   //帖子分享
  //   path: '/Share',
  //   name: 'Share',
  //   component: () => import('../views/ShareView.vue'),
  //   // meta: { isAuth: true }
  // },
  // {
  //   //视频
  //   path: '/Video',
  //   name: 'Video',
  //   component: () => import('../views/VideoView.vue'),
  //   // meta: { isAuth: true }
  // },
  // {
  //   //新闻
  //   path: '/Journalism',
  //   name: 'Journalism',
  //   component: () => import('../views/JournalismView.vue'),
  //   // meta: { isAuth: true }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 添加全局路由守卫
// router.beforeEach((to, from, next) => {
//   if (to.meta.isAuth) { //判断是否需要鉴定拦截
//     if (sessionStorage.getItem("token") != null) {
//       next()
//       // 登录了就通行
//     } else {
//       alert('请先登录')
//       next('/')
//       // 未登录拦截到登录页
//     }
//   } else {
//     next()
//     //未设置meta: { isAuth: true }不需要鉴定
//   }
// })

export default router
