<!-- 头部组件 -->
<template>
  <div class="HeadAssembly">
    <el-menu class="el-menu-demo" background-color="#76D7C4" text-color="#fff">
      <!-- 页面信息 -->
      <el-menu-item
        :index="'1-' + index"
        v-for="(item, index) in itemtext"
        :key="index"
        @click="Onitemtext(item.path)"
        :class="{ ifItemborder: item.path == $route.path }"
      >
        <h3 :class="{ ifPcolor: item.path == $route.path }">{{ item.text }}</h3>
      </el-menu-item>
      <!-- 下拉框 -->
      <!-- <el-dropdown>
        <h3 class="el-dropdown-link">
          <img
            src="../assets/Photo/logo.png"
            alt=""
            width="56px"
            class="el-icon-arrow-down el-icon--right"
          />
        </h3>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in dropdowntext" :key="index">
            <h4
              @click="Ondropdowntext(item.path)"
              :class="{ ifH4color: item.path == $route.path }"
            >
              {{ item.text }}
            </h4>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "HeadAssembly",
  data() {
    return {
      itemtext: [
        // { text: "首页", path: "/home" },
        { text: "首页", path: "/Photo" },
      ],
      dropdowntext: [
        { text: "个人中心", path: "/Personal" },
        { text: "关于我们", path: "/AboutMy" },
        { text: "发布分享", path: "/Share" },
        { text: "分享详情", path: "/ShareDetails" },
        { text: "新闻", path: "/Journalism" },
        { text: "退出登录", path: "/" },
      ],
    };
  },
  methods: {
    //点击跳转页面
    Onitemtext(path) {
      this.$router.push(path);
    },
    //是否退出登录
    Ondropdowntext(path) {
      if (path == "/") {
        if (confirm("确认退出吗？")) {
          // 退出时执行
          this.$router.push("/");
          //清空本地存储
          sessionStorage.clear();
        }
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.HeadAssembly {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  & > .el-menu-demo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.8;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.ifPcolor {
  color: black;
}
.ifH4color {
  color: #76d7c4;
}
.ifItemborder {
  border-bottom: 2px solid black;
}
</style>