<!-- 首页 -->
<template>
  <div class="home">
    <HeadAssemblyVue></HeadAssemblyVue>
    <!-- 视频背景 -->
    <div class="block">
      <el-carousel height="100vh">
        <el-carousel-item
          v-for="(item, index) in video"
          :key="index"
          class="el-carousel-item"
        >
          <h1>{{ item.text }}</h1>
          <!-- <video autoplay muted loop>
            <source :src="item.src" type="video/mp4" />
          </video> -->
          <img :src="item.src" alt="">
        </el-carousel-item>
      </el-carousel>
      <!-- 网站介绍 -->
      <article>
        <p>
          自2022年以来，我们一直在拍摄最重要，最有趣的各个角落。我们的工作最杰出的成果之一就是我们仍在从事的项目。在我们的网站上，展示有约30多个地方的3,00多张全景照片，包括北极，南极，火山喷发，甚至是大气平流层的全景图。每周我们都会在网站上发布一个新的虚拟之旅。
        </p>
      </article>
      
    </div>
  <div class="bottom">
      <BottomAssembly></BottomAssembly>
    </div>
  </div>
</template>

<script>
import HeadAssemblyVue from "@/components/HeadAssembly.vue";
import BottomAssembly from "@/components/BottomAssembly.vue"

export default {
  name: "HomeView",
  components: { HeadAssemblyVue , BottomAssembly},
  data() {
    return {
      video: [
        {
          text: "TheSky",
          src: 'https://cdn.colorhub.me/HmUOQ2X0lfc/rs:auto:0:500:0/g:ce/fn:colorhub/bG9jYWw6Ly8vMmQvNmIvOTllMjYyMGQwYTRmOTg2NDk0OTJlZmY4ZGYyYzBkZmVlZjA3MmQ2Yi5qcGVn.webp',
        },
        {
          text: "WhiteClouds",
           src: "https://img.colorhub.me/GO_g7MoOL78/rs:auto:0:500:0/g:ce/fn:colorhub/bG9jYWw6Ly8vMTYvZWYvN2NlYzY2Y2YyZjA5Y2I3NWE1OWFhMThiNDE2OTJkYTEzMGY0MTZlZi5qcGVn.webp",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.el-carousel-item {
  position: fixed;
}
video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
}
h1 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #40e0d0;
  letter-spacing: 20px;
  z-index: 1;
  font-size: 120px;
  opacity: 0.6;
}
article {
  background: black;
  color: #fff;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 15% 0;
  opacity: 0.7;
  z-index: 5;
}
article p {
  width: 75%;
  margin: 0 auto;
  font-size: 26px;
}
.bottom{
  position: absolute;
  bottom: 0;
  z-index: 10;
}
</style>