<template>
    <div class="dag">
        <div class="dag1">缘英科技 TheSKY 2024 © 版权所有
            <div @click="goPage" style="text-align:center;cursor: pointer;">备案号：渝ICP备2024037286号-1</div>
        </div>
        <!-- <div class="information">
            <img src="https://airpano.org.cn/css/img/appstore.png" alt="">
            <p class="text-head">我们所在的社区:</p>
            <img class="imgs"
                src="https://img1.baidu.com/it/u=2730544537,3038526257&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                alt="">
            <img class="imgs"
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.leixue.com%2Fuploads%2F2020%2F11%2FApple.png%21760&refer=http%3A%2F%2Fwww.leixue.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1675062855&t=36df9538e272b5c2e25068aa04130ff8"
                alt="">
            <img class="imgs" src="https://airpano.org.cn/images/social/smug.png" alt="">
            <img class="imgs"
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.ithome.com%2Fnewsuploadfiles%2F2021%2F7%2Fceb1038d-5cf6-47c0-8045-ec7ada876ca3.png&refer=http%3A%2F%2Fimg.ithome.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1675063519&t=74b690e5d3e18c7a88480ea7a49ecae6"
                alt="">
            <div class="text-ber">
                <p>我们的全景图、全景视频和照片被国际知名公司广泛使用。AirPano的客户和合作伙伴包括华为、谷歌、微软、Adobe、Facebook、LG、三星、诺基亚等。iOS版AirPano Travel Book
                    app被苹果公司评为2014年度最佳应用之一。</p>
            </div>
        </div> -->


        <!-- <div class="middle">
            <div class="bar-ber bar-ber">
                <ul>
                    <h2> 信息</h2>
                    <li><a href="">新闻</a></li>
                    <li><a href="">关于sky</a> </li>
                    <li><a href="">sky环游地图</a></li>
                    <li><a href="">我们的团队</a></li>
                    <li><a href="">文章</a></li>
                    <li><a href="">联系我们</a></li>
                    <li><a href="">常见问题</a></li>
                    <li><a href="">引用规则</a></li>
                    <li><a href="">合作伙伴和客户</a></li>
                </ul>
            </div>
            <div class="bar-middle bar-ber">
                <ul>
                    <h2>产品与服务 </h2>
                    <li><a href="">我们的服务</a></li>
                    <li><a href="">360°图片</a></li>
                    <li><a href="">360°视频</a></li>
                    <li><a href="">360°全景图</a></li>
                    <li><a href="">360°延迟拍摄</a></li>
                    <li><a href="">全镜漫游</a></li>
                    <li><a href="">图片库</a></li>
                    <li><a href="">360°视频库</a></li>
                    <li><a href="">应用</a></li>
                    <li><a href="">全镜影院</a></li>
                    <li><a href="">触摸屏</a></li>
                </ul>
            </div>
            <div class="bar-trail bar-ber">
                <ul>
                    <h2>链接 </h2>
                    <li><a href="">sky南极洲</a></li>
                    <li><a href="">sky IOS版</a></li>
                    <li><a href="">sky TV版</a></li>
                </ul>
            </div>
        </div> -->

    </div>
</template>

<script>
export default {
    name: 'SkyBottomAssembly',
    data() {
        return {
        };
    },
    methods: {
        goPage() {
            window.location.href = 'https://beian.miit.gov.cn/';
        },
    },
};
</script>

<style  scoped>
.dag {
    height: 100px;
    width: 100%;
    background-color: rgb(57, 56, 56);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
</style>